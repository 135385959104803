

body {
  background: url(coffee-apple.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: 'Teko', sans-serif; /* Add your desired font-family */
}


button {
  border: 0;
  padding: 10px 30px;
  background: #9f8500;
  color: white;
}

.title {
  color: #cdcdcd,
}

.subtitle {
  color: tan;
}
.typedContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
}